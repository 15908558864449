import React from "react";

const ContactUsPage = () => {
  return (
    <section className="bg-[#0F0F0F] flex items-center justify-center">
   </section>
  );
};

export default ContactUsPage;
